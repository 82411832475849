.course-videos-container {
  display: grid;
  gap: 1rem;
}

.course-videos-container button {
  width: fit-content;
  background-color: #ccc;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  cursor: pointer;
}

.table-container {
  overflow-x: auto;
  scroll-behavior: smooth;
  max-height: 500px;
  width: 100%;
}
.videos-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 20px; */
}

.videos-table th {
  border: 1px solid #ccc;
  background-color: #80ad2d;
  font-size: 20px;
  color: #ffff;
  padding: 10px;
  text-align: left;
}
.videos-table td {
  border: 1px solid #ccc;
  font-size: 18px;
  padding: 10px;
  text-align: left;
}

.videos-table button {
  border: none;
  color: #1868ea;
  font-size: 20px;
  background-color: #fff;
}

@media (max-width: 780px) {
  .videos-table {
    display: block;
    overflow-x: auto;
  }

  .videos-table th,
  .videos-table td {
    white-space: nowrap;
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .videos-table {
    display: block;
    overflow-x: auto;
  }

  .videos-table th,
  .videos-table td {
    white-space: nowrap;
    font-size: 10px;
  }
}
