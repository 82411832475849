.video-container {
  display: grid;
}
.video-container button {
  width: fit-content;
  background-color: #ccc;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  cursor: pointer;
}

.video-container video {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 600px;
}

.video-container img {
  position: absolute;
  right: 0;
  top: 150px;
  width: 8rem;
  opacity: 0.7;
}

@media (max-width: 720px) {
  .video-container img {
    position: absolute;
    right: 20px;
    top: 140px;
    width: 4rem;
    opacity: 0.7;
  }
}

@media (max-width: 500px) {
  .video-container img {
    position: absolute;
    right: 20px;
    top: 120px;
    width: 3rem;
    opacity: 0.7;
  }
}
