 .Login-model
 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffff;
  z-index: 1;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffff;
  border-radius: 15px; 
  max-width: 1200px; 
  max-height: 700px; 
  width: 100%; 
  height: 100%; 
  box-shadow: 0 0 20px #00000080; 
}

  .modal-content img {
    width: 100%;
    max-width: 1000px;
    max-height: 700px;
    object-fit: cover;
    border-bottom-left-radius: 10px;
    margin: 0;
  }
  
  .Login {
    background: #ffff;
    row-gap: 10px;
    padding: 10px;
    align-self: center;
    font-size: 30px;
    margin: 0 5px;
  }
  .Login .odigos{
    position: absolute;
    right: 0;
    top: 10px;
    width: 6rem;
  } 
  
  .Login input {
    width: 450px;
    height: 40px;
    font-size: 20px;
    border-color: 1px #b7b7b7;
    border-radius: 8px;
    background: #ffff;
    text-indent: 10px;
    margin: 10px 30px;
  }
  
  .Login button {
    font-size: 20px;
    width: 450px;
    height: 40px;
    background: #100a53;
    color: #ffff;
    border-radius: 8px;
    border-style: none;
    margin: 10px 30px;
    cursor: pointer;
  }
  .Login .resend {
    font-size: 18px;
    justify-self: right;
    background-color: #fff;
    color:#100a53;
    border:none;
    cursor: pointer;
  }
  
  .Login h2 {
    text-align: start;
    font-size: 40px;
    color: #100a53;
    margin: 15px 30px;
  }
  
  .Login a {
    font-size: 20px;
    margin: 10px 20px;
  }
  
  .Login p {
    color: #f87171;
    font-size: 15px;
    margin: 5px 30px;
  }
  
  @media (max-width:780px) {
    .modal-content img {
      width: 100%;
      max-width: 400px;
      max-height: 450px;
      object-fit: cover;
      margin: 0;
    }
  
    .modal-content {
      max-width: 800px;
      width: 100%;
      height: auto;
      overflow: hidden;
    }
  
    .Login {
      background: white;
      align-self: center;
      font-size: 20px;
      width: 370px;
    }
    .Login .odigos{
      position: absolute;
      right:0%;
      top:0;
      width: 6rem;
    } 
  
    .Login input {
      width: 100%;
      max-width: 250px;
      box-sizing: 30px;
      height: 40px;
      border-radius: 8px;
      background: #ffff;
      border-color:#f1f1f1;
      margin: 10px 20px;
    }
  
    .Login button {
      width: 100%;
      max-width: 250px;
      box-sizing: 30px;
      height: 40px;
      background: #100a53;
      color: white;
      border-radius: 8px;
      border-style: none;
      margin: 10px 20px;
    }
  
    .Login a {
      font-size: 18px;
      margin: 10px 20px;
    }
  }
  
  @media (max-width:650px) {
    .modal-content >img {
      display: none;
    }
  
    .modal-content {
      width: 400px;
      height: 500px;
      border-radius: 5px;
    }
  
    .Login {
      background: white;
      align-self: center;
      font-size: 18px;
      max-width: 400px;
      width: 100%;
      row-gap: 10px;
    }
    .Login .odigos {
      position: absolute;
      left:50%;
      top: 15%;
      transform: translate(-50%,-50%);
      width: 7rem;
    } 
  
    .Login input {
      width: 100%;
      max-width: 280px;
      box-sizing: 30px;
      height: 40px;
      border-radius: 8px;
      background: #ffff;
      border-color:#f1f1f1 ;
      margin: 5px 20px;
      font-size: 20px;
    }
  
    .Login button {
      width: 100%;
      max-width: 280px;
      box-sizing: 30px;
      height: 40px;
      padding: 10px;
      border-radius: 8px;
      border-style: none;
      margin: 5px 20px;
      font-size: 20px;
    }
  
    .Login a {
      font-size: 15px;
      margin: 10px 20px;
    }
  }
  
  @media (max-width:380px) {
  
    .modal-content {
      width: 300px;
      height: 500px;
      border-radius: 5px;
    }
  
    .Login {
      background: white;
      align-self: center;
      font-size: 15px;
      max-width: 300px;
      width: 95%;
      row-gap: 10px;
    }
    .Login .odigos {
      position: absolute;
      left:50%;
      top: 15%;
      transform: translate(-50%,-50%);
      width: 7rem;
    }
    .Login input {
      width: 100%;
      max-width: 250px;
      box-sizing: 30px;
      height: 40px;
      border-radius: 8px;
      background: #ffff;
      border-color:#f1f1f1 ;
      margin: 5px 10px;
      font-size: 20px;
    }
  
    .Login button {
      width: 100%;
      max-width: 250px;
      box-sizing: 30px;
      height: 40px;
      border-radius: 8px;
      border-style: none;
      margin: 5px 10px;
      font-size: 20px;
    }
  
    .Login a {
      font-size: 13px;
      margin: 10px 20px;
    }
  }