* {
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
}

main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  background: #000;
  color: #fff;
  height: 100vh;
  transition: transform 3s ease-in-out;
  position: sticky;
  top: 0;
}

.top_section {
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  padding: 15px;
  transition: transform 3s ease-in-out;
}

.top_section img {
  width: 100%;
}

.bars {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
  margin-left: 50px;
  cursor: pointer;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  background: #87cefa;
  color: #ffff;
  transition: all 0.5s;
}

.activeItem {
  background: #87cefa;
  color: #000;
}

.icon,
.link_text {
  font-size: 20px;
}

.logout-button {
  background-color: #000;
  display: flex;
  color: #fff;
  border: none;
  padding: 10px 13px;
  gap: 20px;
  transition: all 0.5s;
}

.logout-icon {
  font-size: 20px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.pagination li {
  margin-right: 10px;
}

.pagination li a {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid #ccc;
  color: black;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
}

.pagination li a:hover {
  background-color: #002255;
  color: #fff;
}

.pagination .active a {
  background-color: #002255;
  color: #ffff;
  font-weight: bold;
}
.pagination .disabled a {
  background-color: #d3d3d3;
  color: white;
  border-color: transparent;
}
.pagination .disabled a:hover {
  background-color: #002255;
}
/* Styling previous and next buttons */
.pagination .previous a,
.pagination .next a {
  background-color: #002255;
  border: 1px solid #002255;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

/* Pagination separator style (e.g., "..." between pages) */
.pagination .break a {
  border: none;
  color: #1e87db;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
