body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
h1 {
  color: #a3a817;
  font-size: 1.8rem;
  margin: 0px;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 400px) {
  h1 {
    font-size: 18px;
  }
}
